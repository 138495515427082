.beforeCheckout {
  margin-bottom: 0rem;
}
.beforeCheckout .beforeCheckoutHead {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.beforeCheckout .beforeCheckoutHead p {
  margin-bottom: 0;
}
@media (min-width: 992px) {
.beforeCheckout .beforeCheckoutHead p {
    font-size: 18px;
}
}
.beforeCheckout .boxsetResume {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 26px;
}
.beforeCheckout .boxsetResume > * {
  margin: 0 8px;
}
.beforeCheckout .col-lg-6,
.beforeCheckout .panel {
  min-height: 100%;
}
.beforeCheckout .panelTop {
  text-align: center;
}
.beforeCheckout .panelTop strong {
  display: block;
  font-size: 26px;
  margin-bottom: 2rem;
}
.beforeCheckout .panelTop p {
  display: block;
  margin: auto;
  margin-bottom: 3rem;
}
.beforeCheckout .steplist {
  margin: 2rem auto;
  max-width: 550px;
}
.beforeCheckout .steplist .step {
  width: 100%;
  text-align: center;
}
.beforeCheckout .steplist .step p {
  font-weight: 500;
}
.beforeCheckout .steplist .stepIcon {
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
.beforeCheckout .steplist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 13px;
    display: flex;
}
.beforeCheckout .steplist .step {
    max-width: 25%;
    min-height: 100%;
    height: inherit;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.beforeCheckout .steplist .stepIcon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 120px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
.beforeCheckout .steplist .arrow {
  display: none;
  text-align: center;
}
.beforeCheckout .steplist .arrow * {
  fill: #f07b5d;
}
@media (min-width: 992px) {
.beforeCheckout .steplist .arrow {
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.beforeCheckout .steplist .arrow svg {
    width: 40px;
    height: auto;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
}
}
.beforeCheckout .accordion {
  max-width: 800px;
  margin: auto;
}
.beforeCheckout .panel {
  padding: 0;
}
.beforeCheckout .panel-tc .panel-icon-title svg {
  width: 140px;
}
.beforeCheckout .panel-scp a:link, .beforeCheckout .panel-scp a:visited, .beforeCheckout .panel-stp a:link, .beforeCheckout .panel-stp a:visited {
  color: #0077CA;
}
.beforeCheckout .panel-scp .panel-icon-title img, .beforeCheckout .panel-stp .panel-icon-title img {
  width: 120px;
}
.beforeCheckout .panel-title {
  font-size: 18px;
  padding: 0.75rem 1.2rem 0.75rem 1.2rem;
  text-align: left;
}
.beforeCheckout .panel-title .panel-title-content {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.beforeCheckout .panel-title.not-collapsed .chevronDown {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.beforeCheckout .panel-title span {
  display: block;
  padding: 0 1rem;
}
.beforeCheckout .panel-title svg {
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: auto;
  width: 30px;
}
.beforeCheckout .panel-title svg.chevronDown {
  max-width: 17px;
}
@media (min-width: 992px) {
.beforeCheckout .panel-title {
    font-size: 22px;
    padding: 1rem 1.25rem;
}
}
.beforeCheckout .panel-content {
  max-width: 700px;
  margin: 0 auto;
  text-align: left;
}
@media (min-width: 992px) {
.beforeCheckout .panel-content {
    padding-left: calc(32px + 1rem);
}
}
.beforeCheckout #accordion-cc svg {
  min-width: 30px;
  height: auto;
  margin-right: 1rem;
}
#edenredLogin .modal-content {
  height: 90vh;
  max-height: 600px;
}
#edenredLogin iframe {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}
@media (min-width: 992px) {
#app.before-checkout #appHeader {
    display: none;
}
}
#app.reservation {
  background-color: #F2F2F2;
}
#checkout {
  padding-top: 0;
}
#checkout .breadcrumbs {
  margin-bottom: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
@media (min-width: 992px) {
#checkout .breadcrumbs {
    margin-bottom: 3rem;
}
}
#checkout #content {
  margin: auto;
  margin-top: 0;
}
@media (min-width: 992px) {
#checkout #sidebar {
    padding-right: 0;
}
}
@media (min-width: 1200px) {
#checkout {
    padding-top: 60px;
}
}
#checkout #main {
  padding-top: 40px !important;
  margin-top: 0 !important;
}
@media (min-width: 768px) {
#checkout #main {
    padding-top: 0 !important;
}
}
@media (max-width: 991.98px) {
#checkout #main {
    margin-left: 0;
    margin-right: 0;
}
}
#checkout .checkin-checkout-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#checkout .checkin-checkout-box > div {
  width: 50%;
}
#checkout .svg-pin {
  margin-right: 4px;
  margin-bottom: 2px;
}
#checkout .svg-pin path {
  fill: #333333;
}
#checkout .terms-link {
  color: #0077CA;
  cursor: pointer;
}
#checkout #content {
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
  max-width: 100%;
}
@media (min-width: 992px) {
#checkout {
    padding-top: 30px;
}
#checkout #content {
    padding-left: 0;
    padding-right: 0;
}
}
#checkout .confirmSidebarTitle {
  color: #333333;
  font-weight: normal;
}
#checkout .svgHands {
  min-width: 48px;
}
.confirmSidebar .boxsetResume {
  margin-top: -6px;
}
.confirmSidebar .boxsetIcon {
  width: 22px;
  margin-right: 6px;
}
.confirmSidebar .boxsetName {
  font-size: 1.15rem;
}
.confirmSidebar .salaBadge {
  font-size: 14px;
}
.confirmSidebar .boxsetIcon {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.confirmSidebar .boxsetName {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.confirmSidebar strong {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.confirmSidebar .salaBadge.boxsetBackground {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}
.confirmSidebar .salaBadge:not(.boxsetBackground) {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}
.confirmSidebar .small {
  line-height: 140%;
  margin-bottom: 0;
}
.deleteReservation {
  margin-bottom: 4rem;
  text-align: center;
}
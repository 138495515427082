@media (min-width: 992px) {
#modal-scopri-disponibilita-checkout {
    background: #F2F2F2;
    border-radius: 30px;
    margin-top: 20px;
}
}
#modal-scopri-disponibilita-checkout footer {
  display: none;
}
#modal-scopri-disponibilita-checkout #searchBar {
  padding: 20px;
}
#modal-scopri-disponibilita-checkout #searchBar .userBox,
#modal-scopri-disponibilita-checkout #searchBar .breadcrumbs,
#modal-scopri-disponibilita-checkout #searchBar #search-form-filters {
  display: none !important;
}
#modal-scopri-disponibilita-checkout #searchBar #search-form {
  padding: 0;
  background: transparent;
}
#modal-scopri-disponibilita-checkout #searchBar form {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 13px;
}
#modal-scopri-disponibilita-checkout #searchBar form input, #modal-scopri-disponibilita-checkout #searchBar form select, #modal-scopri-disponibilita-checkout #searchBar form .dropdown-toggle {
  font-size: 13px;
}
#modal-scopri-disponibilita-checkout #searchBar .search-form-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
#modal-scopri-disponibilita-checkout #searchBar .search-form-item:not(#search-form-buttons) {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
.boxset .showBookingEngineButtonMobile {
    display: none;
}
}
.boxset .boxsetWrapper {
  display: block;
  width: 100%;
}
.boxset .smallText {
  font-size: 14px;
}
.boxset ol > li {
  list-style-type: disc;
  margin-bottom: 15px;
}
.boxset .boxsetResumeMessage {
  width: 100%;
}
.boxset .primaryBackground {
  background: #f07b5d;
}
.boxset .voucherLeft {
  position: relative;
  margin-bottom: 30px;
}
.boxset .voucherLeft img {
  width: 200px;
  display: block;
  margin: 0 auto;
}
.boxset .purchaseValuePropositionsCheckoutWrapper {
  margin: 40px 0 50px;
}
.boxset .valuePropositionsCheckoutWrapper {
  margin-top: 60px;
}
.boxset .salaBadges {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 100%;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
}
.boxset .prefooter-blocks-large .block-wrap svg {
  margin-bottom: 1rem;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.boxset .prefooter-blocks-large .block-wrap strong {
  font-size: 16px;
}
.boxset .prefooter-blocks-large .block-wrap p {
  font-size: 14px;
}
.boxset .infoBoxsetPurchase {
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  max-width: 660px;
}
.boxset .infoBoxsetPurchase .alert {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.boxset .infoBoxsetPurchase p {
  margin-bottom: 0;
}
.boxset .infoBoxsetPurchase .infoBoxsetPurchaseCopy {
  font-weight: normal;
  color: #333333;
}
.boxset .voucherAlert {
  margin-top: 30px;
  font-size: 14px;
  padding: 1rem 1.5rem;
}
.boxset .voucherAlert .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.boxset .voucherAlert svg {
  width: 30px;
  min-width: 30px;
  margin-right: 16px;
}
.boxset .voucherAlert .voucherAlertLink {
  min-width: 100%;
  width: 100%;
  display: block;
  text-align: right;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 1rem;
}
.boxset .voucherAlert .voucherAlertLink a {
  text-decoration: underline;
  color: #fff;
}
.boxset .voucherAlert .voucherAlertLink a:hover {
  color: #fff;
}
.boxset .confirmButtons {
  max-width: 700px;
  margin: auto;
}
.boxset .voucherInfo {
  margin-top: 30px;
  font-size: 14px;
}
.confirm-boxset .boxset .voucherInfo {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.boxset .voucherInfo .voucherInfoTitle {
  color: #fff;
  font-size: 0.95rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.boxset .voucherInfo .voucherInfoTitle svg {
  width: 30px;
  min-width: 30px;
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
.boxset .voucherInfo .voucherInfoTitle {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
.boxset .voucherInfo .voucherInfoText {
  color: #fff;
}
.boxset .voucherInfo .voucherInfoLink {
  display: block;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}
.boxset .voucherInfo .voucherInfoLink a {
  text-decoration: underline;
  color: #fff;
  font-size: 11px;
  display: block;
  text-align: center;
}
.boxset .voucherInfo .voucherInfoLink a:hover {
  color: #fff;
}
.boxset .giftCardInfo {
  padding: 0;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}
.boxset .giftCardInfo .giftCardInfoText {
  padding: 15px;
}
.boxset .giftCardInfo p {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
.boxset .boxsetWrapper .col-lg-4,
.boxset .boxsetWrapper .col-lg-8 {
    padding-right: 0;
}
}
@media (max-width: 575.98px) {
.giftCardWrapper {
    display: none !important;
}
.modalBookingEngineCheckout #search-form-datepicker,
.modalBookingEngineCheckout #search-form-nights,
.modalBookingEngineCheckout #search-form-occupancy,
.modalBookingEngineCheckout #search-form-buttons {
    display: block;
}
.modalBookingEngineCheckout #toggle-search-settings {
    display: none !important;
}
#scopri-cosa-puoi-prenotare,
#modal-scopri-disponibilita-checkout {
    display: none;
}
.boxsetLeftColumn {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin: 2rem 0;
}
.boxsetLeftColumn .purchasingText {
    display: none;
}
.boxsetLeftColumn #modal-scopri-disponibilita-checkout #searchBar {
    background: #F2F2F2;
}
}
.checkoutVoucherBookinEngine .panel-dark-blue {
  padding: 1.2rem 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.checkoutVoucherBookinEngine .panel-dark-blue * {
  font-size: 0.95rem;
}
.checkoutVoucherBookinEngine .engineDesktop {
  margin-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.purchaseValueProposition .value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.purchaseValueProposition .valueIcon {
  margin-bottom: 20px;
}
.purchaseValueProposition .valueIcon svg {
  width: 40px;
  height: 40px;
  max-width: none;
}
.purchaseValueProposition .valueText {
  font-weight: bold;
}
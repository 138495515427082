#checkout {
  padding-top: 0;
}
#checkout #main {
  display: block;
}
#checkout .breadcrumbs {
  margin-bottom: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
@media (min-width: 992px) {
#checkout .breadcrumbs {
    margin-bottom: 2rem;
}
}
#checkout #content {
  min-width: 100%;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
#checkout #sidebar {
  background: transparent;
  min-width: 100%;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
#checkout #sidebar .sidebar-inner {
  background: transparent;
}
#checkout .checkoutBookingEngine form {
  display: block !important;
}
#checkout .checkoutHead {
  text-align: center;
}
#checkout .checkoutBack {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
#checkout .checkoutBack {
    text-align: left;
}
}
@media (min-width: 992px) {
#checkout {
    padding-top: 30px;
}
#checkout #main {
    padding-left: 60px;
    padding-right: 60px;
}
}
@media (max-width: 575.98px) {
#checkout .boxsetLeftColumn .voucherLeft,
#checkout .boxsetLeftColumn .engineDesktop,
#checkout .boxsetLeftColumn .voucherInfo {
    display: none;
}
#checkout .boxsetRightColumn .voucherAlert,
#checkout .boxsetRightColumn .valuePropositionsCheckoutWrapper {
    display: none;
}
#checkout .boxsetRightColumn .infoBoxsetPurchaseWrapper {
    margin-top: 1rem;
    font-size: 13px;
}
#checkout .boxsetRightColumn .boxsetResume {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#checkout .boxsetRightColumn .boxsetResume .boxsetIcon {
    width: 20px;
    height: auto;
}
#checkout .boxsetRightColumn .boxsetResume .boxsetName {
    font-size: 16px;
}
#checkout .boxsetRightColumn .boxsetResume strong,
#checkout .boxsetRightColumn .boxsetResume .expire,
#checkout .boxsetRightColumn .boxsetResume .salaBadge {
    font-size: 14px;
}
#checkout .boxsetRightColumn .boxsetResume .expire {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: auto;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 12px;
}
#checkout .boxsetRightColumn .salaBadge {
    padding: 3px 10px;
}
#checkout .boxsetRightColumn .purchaseValueProposition .valueIcon {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
}
#checkout .boxsetRightColumn .purchaseValueProposition .valueText {
    padding-left: 0.5rem;
    font-size: 12px;
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
#checkout .breadcrumbs {
    padding: 0 0 0 0;
    margin: 1.5rem 0 0 0;
}
#checkout .termsShortExpanded {
    font-size: 14px;
}
#checkout .customInputBlock {
    margin-bottom: 0;
}
#checkout .boxset .boxsetResume strong {
    font-size: 16px;
}
#checkout .boxset .boxsetResume .expire {
    margin-top: 0.5rem;
}
#checkout .boxset .boxsetLeftColumn .purchasingText {
    margin-top: 2rem;
}
#checkout .boxset .boxsetLeftColumn .checkoutVoucherBookinEngine {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: calc(100% - 80px);
}
#checkout .boxset .boxsetLeftColumn .checkoutVoucherBookinEngine #search-form-datepicker,
#checkout .boxset .boxsetLeftColumn .checkoutVoucherBookinEngine #search-form-nights,
#checkout .boxset .boxsetLeftColumn .checkoutVoucherBookinEngine #search-form-occupancy {
    width: 100%;
    max-width: 100% !important;
}
#checkout .prefooter-blocks-large .block-wrap {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    max-width: 100%;
}
#checkout .prefooter-blocks-large .block-wrap:not(:last-child) {
    padding-bottom: 2rem;
}
}
#sidebarCheckout {
  background: #fff;
  padding: 0;
}
#sidebarCheckout .sidebarStep {
  padding: 30px 30px;
  margin-bottom: 60px;
  border-radius: 6px;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
#sidebarCheckout .sidebarStep:not(:last-child) {
  border-bottom: 1px solid rgba(51, 51, 51, 0.25);
}
#sidebarCheckout .sidebarStep.bgWhite {
  background-color: #fff;
  border: 1px solid #F2F2F2;
}
#sidebarCheckout .sidebarStep.bgDark {
  background-color: #292E44;
}
#sidebarCheckout .sidebarStep.bgDark * {
  color: #fff;
  border-color: #fff;
}
#sidebarCheckout .sidebarStep.bgDark > hr {
  display: block;
  border-top: 1px solid #fff;
  margin: 30px 0;
  width: calc(100% + 60px);
  margin-left: -30px;
}
#sidebarCheckout .sidebarStep.bgDark > div hr {
  border-top: 2px solid #fff;
  opacity: 0.5;
}
#sidebarCheckout .sidebarStepTitle {
  color: #333333;
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
}
@media (min-width: 768px) {
#sidebarCheckout .sidebarStepTitle {
    font-size: 16px;
}
}
@media (min-width: 1200px) {
#sidebarCheckout .sidebarStepTitle {
    font-size: 18px;
}
}
#sidebarCheckout .sidebarRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 15px;
}
#sidebarCheckout .sidebarBlockTitle {
  color: #333333;
  display: block;
  width: 100%;
  text-align: center;
}
#sidebarCheckout .svgInfo {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 0 8px 0 0;
}
@media (max-width: 575.98px) {
#sidebarCheckout .sidebarStep {
    padding: 15px 15px;
    font-size: 13px;
}
#sidebarCheckout .sidebarStep .customInputBlock {
    margin-bottom: 0;
}
#sidebarCheckout .sidebarStep > hr {
    margin: 20px 0 !important;
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
}
}
#sidebarCheckout .sidebarStep.bgGray {
  border: 1px solid #F2F2F2;
}
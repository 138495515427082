#confirm.confirmBoxset {
  min-height: 100vh;
  padding: 30px 0 60px 0;
  /*
  .container
  {
      max-width: 1200px;
      margin:0 auto;
  }
  */
}
#confirm.confirmBoxset .panel-primary.bgDark {
  background-color: #292E44;
}
#confirm.confirmBoxset .panel-primary.bgDark * {
  color: #fff;
}
#confirm.confirmBoxset .panel-primary.bgDark hr {
  border-color: #fff;
}
#confirm.confirmBoxset .smallText {
  font-size: 14px;
}
#confirm.confirmBoxset .purchasingText {
  display: none;
}
#confirm.confirmBoxset #main {
  display: block;
}
#confirm.confirmBoxset .breadcrumbs {
  margin-bottom: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
@media (min-width: 992px) {
#confirm.confirmBoxset .breadcrumbs {
    margin-bottom: 3rem;
}
}
#confirm.confirmBoxset #content {
  min-width: 100%;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
#confirm.confirmBoxset #sidebar {
  background: transparent;
  min-width: 100%;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
#confirm.confirmBoxset #sidebar .sidebar-inner {
  background: transparent;
}
#confirm.confirmBoxset .confirmBoxsetHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#confirm.confirmBoxset .confirmBoxsetHeading span {
  display: inline-block;
  margin-right: 1.5rem;
}
#confirm.confirmBoxset .boxset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0;
}
#confirm.confirmBoxset .boxsetLeft {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 150px;
}
#confirm.confirmBoxset .boxsetRight {
  -webkit-box-flex: 1%;
      -ms-flex: 1%;
          flex: 1%;
  max-width: calc(100% - 150px);
  padding: 0 30px;
}
#confirm.confirmBoxset .boxsetImg {
  width: 200px;
  max-width: 100%;
}
#confirm.confirmBoxset .sidebarResumeBoxset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#confirm.confirmBoxset .boxsetResumeConfirm strong:not(.boxsetName),
#confirm.confirmBoxset .boxsetResumeConfirm .expire {
  display: none;
}
#confirm.confirmBoxset .boxsetResumeConfirm .boxsetResume {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#confirm.confirmBoxset .boxsetResumeConfirm .boxsetResume strong {
  font-size: 16px;
}
#confirm.confirmBoxset .boxsetResumeConfirm .salaBadge {
  font-size: 14px;
}
#confirm.confirmBoxset .boxsetResumeConfirm small {
  line-height: 80%;
}